import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as billingActions from 'actions/billing';
import * as authActions from 'actions/auth';
import {
  ADYEN_RESULT_TYPES,
  PAYMENT_PROVIDERS,
} from 'utils/constants';
import { locationShape } from 'router/prop-types';
import { useAnalytics } from 'components/Tracking';
import Receipt from './Receipt';
import PaymentFailed from './PaymentFailed';

export const ERROR_RESPONSES = [
  ADYEN_RESULT_TYPES.REFUSED,
  ADYEN_RESULT_TYPES.ERROR,
];

function ReceiptView({ location }) {
  const analytics = useAnalytics();
  const dispatch = useDispatch();

  const {
    paymentResult,
    packageId,
    originalOfferId,
    createAccount,
    promoCode,
    redirectTo,
    refusalReason,
    redirectResult,
    transactionId,
  } = location.query;
  const bypassCheckout = location.query.hasOwnProperty('bypassCheckout');

  const {
    isAdyenDropin,
    userId,
  } = useSelector(({ auth, settings }) => ({
    isAdyenDropin: settings.features.payment.provider.type === PAYMENT_PROVIDERS.ADYEN_DROPIN,
    userId: auth.userId,
  }), shallowEqual);

  if (isAdyenDropin && ERROR_RESPONSES.includes(paymentResult)) {
    return (
      <PaymentFailed reason={refusalReason} />
    );
  }

  const trackSubscription = (pack, transaction) => {
    // EntitlementSubscribeType -> Subscribe, Buy ...
    const type = pack.__typename.slice(11, -4);

    analytics.onOffer(type, {
      userId,
      id: pack.id,
      name: pack.name,
      ...(pack.recurringPeriod ? { recurringPeriod: pack.recurringPeriod } : {}),
      ...(pack.entitlementDurationSec ? { durationSec: pack.entitlementDurationSec } : {}),
      promoCode,
      currency: transaction.currency,
      netPrice: transaction.netPrice,
      grossPrice: transaction.grossPrice,
      transactionId: transaction.transactionId,
      bypassCheckout,
      transactionType: transaction.transactionType,
    });
  };

  return (
    <Receipt
      createAccount={createAccount !== undefined}
      promoCode={promoCode}
      redirectTo={redirectTo}
      offerId={packageId}
      originalOfferId={originalOfferId}
      transactionId={transactionId}
      redirectResult={redirectResult}
      onPaymentSucceded={trackSubscription}
      onClickNext={() => dispatch(authActions.openInitialView())}
      bypassCheckout={bypassCheckout}
    />
  );
}
ReceiptView.propTypes = {
  location: locationShape.isRequired,
};

export async function initReceiptView() {
  await billingActions.getPaymentMethod();
}

export default React.memo(ReceiptView);
